import 'tailwindcss/base.css';
import 'tailwindcss/components.css';
import './main.css';
import 'tailwindcss/utilities.css';

import '@splidejs/splide/dist/css/splide-core.min.css';
import 'glightbox/dist/css/glightbox.css';

import './scripts/defauts';
import './scripts/nav';

import GLightbox from 'glightbox';

import 'intersection-observer';

// eslint-disable-next-line no-unused-vars
const lightbox = GLightbox();
