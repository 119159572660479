import Splide from '@splidejs/splide';
import GLightbox from 'glightbox';

(() => {
  const bodyObserver = new IntersectionObserver((cb) => {
    if (cb[0].isIntersecting) {
      document.body.classList.remove('entered');
    } else {
      document.body.classList.add('entered');
    }
  });
  bodyObserver.observe(document.querySelector('.body-observer'));

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  document.querySelectorAll('.scroll-top').forEach((elem) => {
    elem.addEventListener('click', scrollTop);
  });

  const sliderOptions = {
    perPage: 4,
    breakpoints: {
      1023: {
        perPage: 3,
      },
      767: {
        perPage: 2,
      },
      479: {
        perPage: 1,
      },
    },
    gap: '2rem',
    pagination: false,
    lazyLoad: true,
    classes: {
      arrows: 'splide__arrows slide-arrows',
      arrow: 'splide__arrow slide-arrow',
      prev: 'splide__arrow--prev slide-prev',
      next: 'splide__arrow--next slide-next',
    },
  };

  document.querySelectorAll('.splide').forEach((elem) => {
    const splide = new Splide(elem, sliderOptions);
    splide.mount();
  });

  // eslint-disable-next-line no-unused-vars
  const lightbox = GLightbox();

  function toggleAccordion() {
    this.classList.toggle('open');
    const accordionContent = this.nextElementSibling;
    if (this.classList.contains('open')) {
      const contentHeight = accordionContent.scrollHeight;
      accordionContent.style.maxHeight = `${contentHeight}px`;
    } else {
      accordionContent.style.maxHeight = '0px';
    }
  }

  function registerAccordions() {
    document.querySelectorAll('.accordion-title').forEach((elem) => {
      elem.addEventListener('click', toggleAccordion);
    });
  }

  registerAccordions();

  if (window.htmx) {
    window.htmx.on('htmx:afterSwap', () => {
      registerAccordions();
    });
  }

  const textAnims = [];
  const $animContainer = document.querySelector('.text-animation');

  function animateText(index, firstAnim = false) {
    if (firstAnim) {
      $animContainer.innerHTML = textAnims[index];
    } else {
      $animContainer.classList.add('leave');
      window.setTimeout(() => {
        $animContainer.classList.remove('leave');
        $animContainer.innerHTML = textAnims[index];
      }, 300);
    }
  }

  if (typeof window.TEXT_ANIMS !== 'undefined' && $animContainer) {
    window.TEXT_ANIMS.forEach((text) => {
      let toPush = '';
      const factor = 0.5 / text.length;
      text.split('').forEach((l, i) => {
        if (l === ' ') {
          toPush += '&nbsp;';
        } else {
          toPush += `<span style="animation-delay: ${(i * factor).toFixed(4)}s">${l}</span>`;
        }
      });
      textAnims.push(toPush);
      toPush = '';
    });
    let animIndex = 0;
    animateText(animIndex, true);
    window.setInterval(() => {
      if (animIndex < textAnims.length - 1) {
        animIndex += 1;
      } else {
        animIndex = 0;
      }
      animateText(animIndex);
    }, 4000);
  }
})();
