(() => {
  const $mobileNav = document.querySelector('nav.mobile-nav');
  const $navTitles = $mobileNav.querySelectorAll('.nav-title');
  const $burgers = document.querySelectorAll('.menu-burger');

  function resetMobileNav() {
    document.body.style.height = null;
    document.body.style.overflow = null;
    $mobileNav.classList.remove('open');
    $navTitles.forEach((elem) => {
      elem.classList.remove('open');
      elem.nextElementSibling.style.maxHeight = 0;
    });
  }

  function toggleMobileNav() {
    $burgers.forEach((elem) => {
      elem.classList.toggle('mobile-nav-visible');
    });
    if (this.classList.contains('mobile-nav-visible')) {
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
      $mobileNav.classList.add('open');
    } else {
      resetMobileNav();
    }
  }

  function toggleSubNav() {
    this.classList.toggle('open');
    const navContent = this.nextElementSibling;
    if (this.classList.contains('open')) {
      const contentHeight = navContent.scrollHeight;
      navContent.style.maxHeight = `${contentHeight}px`;
    } else {
      navContent.style.maxHeight = 0;
    }
  }

  $burgers.forEach((elem) => {
    elem.addEventListener('click', toggleMobileNav);
  });

  $navTitles.forEach((elem) => {
    elem.addEventListener('click', toggleSubNav);
  });

  let resizeTimer;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      resetMobileNav();
      $burgers.forEach((elem) => {
        elem.classList.remove('mobile-nav-visible');
      });
    }, 200);
  });
})();
